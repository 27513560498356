import httpInterceptor from '../helpers/httpInterceptor';
const PRODUCT_END_POINT = process.env.REACT_APP_PRODUCT_API_ENDPOINT;

export default {
  getProducts(params, pageLimit, onlyInconsistence, priceNotIn) {
    let queryParams = `limit=${pageLimit}&only_inconsistent=${onlyInconsistence}`;
    if(priceNotIn) {
      queryParams = queryParams + `&prices_not_in=${priceNotIn}`;
    }
    return httpInterceptor.get(PRODUCT_END_POINT + `multicurrency_products?${queryParams}`, {
      params
    })
  },
  updateProducts(putData) {
    return httpInterceptor.put(PRODUCT_END_POINT + 'multicurrency_products', putData)
  },
  getProductCount(params, onlyInconsistence = false, priceNotIn = '') {
    let queryParams = `only_inconsistent=${onlyInconsistence}`;
    if(priceNotIn) {
      queryParams = queryParams + `&prices_not_in=${priceNotIn}`;
    }
    return httpInterceptor.get(PRODUCT_END_POINT + `multicurrency_products/count?${queryParams}`, {
      params
    })
  },
  removeVariants(params) {
    return httpInterceptor.delete(PRODUCT_END_POINT + 'multicurrency_products?ids=all', {
      params
    })
  }
}