import axios from 'axios';
import Cookies from 'universal-cookie';
import store from '../redux/store';
import { getStoreDetailsFailure } from '../redux/actions/authActions';

// Add a request interceptor 
var httpInterceptor = axios.create({
  responseType: "json"
});

httpInterceptor.interceptors.request.use(function (config) {
  // Do something before request is sent 
  //If the header does not contain the token and the url not public, redirect to login 
  if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'development') {
    const cookies = new Cookies();
    // var accessToken = cookies.get('token');
    var accessToken = sessionStorage.getItem("token");

    //if token is found add it to the header
    if (accessToken) {
      if (config.method !== 'OPTIONS') {
        config.headers.authorization = 'Bearer ' + accessToken;
      }
    } else {
      return Promise.reject();
    }
    return config;
  } else {
    if (config.method !== 'OPTIONS') {
      config.headers.authorization = 'Bearer 123';
    }
    config.headers['X-Shopify-Shop-Domain'] = "roundingruleissue.myshopify.com";
    return config;
  }

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

httpInterceptor.interceptors.response.use(function (response) {
  return response;
},
  function (error) {
    if(error.response) {
      if (error.response.status === 401) {
        store.dispatch(getStoreDetailsFailure(error));
        return Promise.reject({ error: "Invalid Session" });
      } else {
        return Promise.reject({ error: error.response });
      }
    } else {
      return Promise.reject({ error: error });
    }
  }
)

export default httpInterceptor;