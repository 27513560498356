import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import {
  Route,
  Switch,
  useHistory
} from 'react-router-dom';
import { Button, Modal } from '@shopify/polaris';
import AppRootStyles from './AppRoute.module.scss';
import HeaderContainer from '../../containers/HeaderContainer';
import WelcomeContainer from '../../containers/onboarding/WelcomeContainer';
import CreateMarketContainer from '../../containers/onboarding/CreateMarketContainer';
import StoreIntegrationContainer from '../../containers/onboarding/StoreIntegration';
import SetupInstructionContainer from '../../containers/onboarding/SetupInstructionContainer';
import AppEmbededContainer from '../../containers/onboarding/AppEmbeddedContainer';
import {
  getGlobalMarkets
} from '../../redux/actions/globalMarketsActions';
import {
  connect
} from 'react-redux';
import {
  getOnboarding
} from '../../redux/actions/onboardingActions';
import { getEmbededBlockStatus } from '../../redux/actions/embededBlockStatusActions';
import MarketsContainer from '../../containers/MarketsContainer';
import {
  OnboardingStatus
} from '../../constants/common';
import ProductsContainer from '../../containers/ProductsContainer/index';
import SettingContainer from '../../containers/SettingContainer/index';
import SupportContainer from '../../containers/SupportContainer/index';
import FAQContainer from '../../containers/FAQContainer/index';
import {  Frame, Toast } from "@shopify/polaris";
import TimerModal from '../common/timerModal';
import Cookies from 'universal-cookie';
import FrozeAdminPanel from '../../containers/FrozeAdminPanel';
import EmbededAppInstructions from "../../components/common/EmbededAppInstruction";

const AppRoot = (props) => {

  const {
    globalMarketOptions,
    getGlobalMarkets,
    getOnboarding,
    getEmbededBlockStatus,
    onboarding,
    isOnboardingLoading,
    settings
  } = props;

  let history = useHistory();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [openFrozeAdminPanel, setOpenFrozeAdminPanel] = useState(false);
  const [timer, setTimer] = useState();
  const [openImpMsgSection, setOpenImpMsgSection] = useState(true);
  // const [isEmbedBlockModalOpen, setIsEmbedBlockModalOpen] = useState(false);
  const [isShowEmbedBlockErrorToast, setIsShowEmbedBlockErrorToast] = useState(false);
  
  useEffect(() => {
    // If there is no details for onboarding then call the api to get it  
    // history.push("/app/support")
    if (onboarding && Object.keys(onboarding).length >= 0) {
      redirectUser(onboarding);
    }
  }, [onboarding]);


  // Fetch global market list
  useEffect(() => {
    const cookies = new Cookies();
    const mcpFreezeTime = cookies.get(`${props.storeId}_mcp_freezeTime`);
    if(new Date() < new Date(parseInt(mcpFreezeTime))) {
      setTimer(parseInt(mcpFreezeTime) - new Date().getTime());
      setOpenFrozeAdminPanel(true);
    }
    getGlobalMarkets()
    getOnboarding().then((onboardingResponse) => {
      // If onboarding is not started then take user to step0 
      if (Object.keys(onboardingResponse).length === 0) {
        history.push("/app/onboarding/welcome")
      }
      const token = sessionStorage.getItem("token");
      const lastToken = sessionStorage.getItem("last-token");
      if(lastToken && (lastToken === token)) {
        setOpenImpMsgSection(false);
      }
      getEmbededBlockStatus().then((statusData) => {
        // if(Object.keys(onboardingResponse).length > 0 && onboardingResponse.status === OnboardingStatus.SETUP_COMPLETED && statusData.status !== "Enabled") {
        //   setIsEmbedBlockModalOpen(true);
        // }
      });
      setIsAppLoading(false);
    })
  }, [])

  // const getOnboardingDetails = () => {
  //   onboardingApi.getOnboardingDetails().then((response) => {
  //     history.push("/app/onboarding/step0")
  //     // redirectUser(response.data)
  //     setIsApiProcessing(false)
  //   }).catch((error) => {
  //   })
  // }

  // const validateEmbedBlockStatus = async () => {
  //   try {
  //     const data = await getEmbededBlockStatus();
  //     if(data.status === "Enabled") {
  //       setIsEmbedBlockModalOpen(false);
  //     } else {
  //       setIsShowEmbedBlockErrorToast(true);
  //     }
  //   } catch(error) {

  //   }
  // }

  const redirectUser = (onboardingDetails) => {
    // If onboarding status is completed or setup_completed then take user to dashboard
    const pathName = props.location.pathname;

    if(settings.isFroze) {
      history.push("/app/support");
      return;
    }
    if (onboardingDetails.status === OnboardingStatus.ONBOARDING_COMPLETED ||
      onboardingDetails.status === OnboardingStatus.SETUP_STARTED ||
      onboardingDetails.status === OnboardingStatus.SETUP_COMPLETED) {
        if(pathName === "/app/markets") {
          history.push("/app/markets");
          return;
        } else if(pathName === "/app/product-prices") {
          history.push("/app/product-prices");
          return;
        } else if(pathName === "/app/settings") {
          history.push("/app/settings");
          return;
        } else if(pathName === "/app/support") {
          history.push("/app/support");
          return;
        } else if(pathName === "/app/faq") {
          history.push("/app/faq");
          return;
        } else {
          history.push("/app/markets");
          return;
        }
    }

    switch (onboardingDetails.currentStepNumber) {
      case -1:
        history.push("/app/onboarding/welcome")
        break;
      case 0:
        history.push("/app/onboarding/step0")
        break;
      case 1:
        history.push("/app/onboarding/step1")
        break;
      case 2:
        history.push("/app/onboarding/step2")
        break;
      case 3:
          history.push("/app/onboarding/step3")
          break;
      default:
        history.push(`/app/onboarding/step${onboardingDetails.currentStepNumber}`)
        break;
    }

  }

  const closeImpMsgSection = () => {
    sessionStorage.setItem("last-token", sessionStorage.getItem("token"));
    setOpenImpMsgSection(false);
  }
  return (<div className="app">

    {
      (!isAppLoading && globalMarketOptions.length > 0) && < >
        <HeaderContainer {...props} />
        {
          /* <SideBarManager {...props} /> */
        } <Frame>
        <div className={AppRootStyles['app-container']} >
          <div className={"container" + (props.location.pathname === "/app/product-prices" ? " pricing-container" : "")} >
            <Switch >
              <Route path={
                `${props.match.path}/onboarding/welcome`
              }
                component={
                  () => < WelcomeContainer />
                } />
                <Route path={
                `${props.match.path}/onboarding/step0`
              }
                component={
                  () => < SetupInstructionContainer />
                } />
              <Route path={
                `${props.match.path}/onboarding/step1`
              }
                component={
                  () => < CreateMarketContainer />
                } />
              <Route path={
                `${props.match.path}/onboarding/step2`
              }
                component={
                  () => < StoreIntegrationContainer />
                }
              />
              <Route path={
                `${props.match.path}/onboarding/step3`
              }
                component={
                  () => < AppEmbededContainer />
                }
              />
              <Route path={
                `${props.match.path}/markets`
              }
                component={
                  () => < MarketsContainer {...props} />
                }
              />
              <Route path={
                `${props.match.path}/product-prices`
              }
                component={
                  () => < ProductsContainer />
                }
              />
              <Route path={
                `${props.match.path}/settings`
              }
                component={
                  () => < SettingContainer />
                }
              />
              <Route path={
                `${props.match.path}/support`
              }
                component={
                  () => < SupportContainer />
                }
              />
              <Route path={
                `${props.match.path}/faq`
              }
                component={
                  () => < FAQContainer />
                }
              />
            </Switch>
          </div>
          {openFrozeAdminPanel && <FrozeAdminPanel timeInMs={timer} storeId={props.storeId} />}
          {props.location.pathname === "/app/markets" && openImpMsgSection && <div className="impMsgBanner">
            <div className="msgSection">
              <div className="title">Important Info</div>
              <div className="message">Always use our app dashboard to add or modify your product prices. <span className="bold-font">Do not change the prices from Shopify product pages. (Either Manually or using bulk update or by importing a CSV)</span></div>
            </div>
            <div className="btnSection">
              <Button onClick={() => closeImpMsgSection()}>Got it</Button>
            </div>
          </div>}
        </div>
        </Frame>

        {isShowEmbedBlockErrorToast && (
        <Frame>
          <Toast
            error
            duration={1500}
            content={"Please enable embeded block"}
            onDismiss={() => {
              setIsShowEmbedBlockErrorToast(false);
            }}
          />
        </Frame>
      )}

        {onboarding.status === OnboardingStatus.SETUP_STARTED && <TimerModal />}

        {/* <Modal
          open={isEmbedBlockModalOpen}
          onClose={() => setIsEmbedBlockModalOpen(false)}
          title={`Add MCP app to your store`}
          primaryAction={{
            content: "OK",
            disabled: props.embededBlockStatus.isLoading,
            onAction: () => { validateEmbedBlockStatus() },
          }}
        >
          <Modal.Section>
            <EmbededAppInstructions shop={props.storeId} />
          </Modal.Section>
        </Modal> */}
      </ >
    }
    {/* <div className={"app-footer" + (props.location.pathname === "/app/product-prices" ? " app-footer-pricing" : "")}>All Rights Reserved.</div> */}
  </div>
  )
}


const mapStateToProps = (state) => {
  const {
    globalMarketOptions,
    countryOptions,
    currencyOptions
  } = state.globalMarkets

  return {
    isOnboardingLoading: state.onboarding.isLoading,
    onboarding: state.onboarding.onboarding,
    globalMarketOptions,
    countryOptions,
    currencyOptions,
    settings: state.settings,
    storeId: state.auth && state.auth.storeDetails && state.auth.storeDetails.myshopify_domain,
    embededBlockStatus: state.embededBlockStatus,
  }
}

const mapDispatchToProps = {
  getGlobalMarkets,
  getOnboarding,
  getEmbededBlockStatus,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppRoot));