import React, { useState, useEffect, useCallback } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Styles from "./styles.module.scss";
import { productData } from "./data";
import { Banner, ContextualSaveBar, DisplayText, Stack, Pagination, Spinner, Frame, Toast, Loading, Modal, TextContainer, Image, Checkbox, Link, ChoiceList, Button, Popover, Filters, Select } from "@shopify/polaris";
import ManageProducts from '../../components/ManageProducts';
import productsApi from "../../services/productsApi";
import roundingApi from "../../services/roundingApi";
import { transformProducts, numberParser, getPrimaryMarket, handleCellClass, rowGroupHeaderName, numberValueFormatter, checkEditableFlag, convertProductsIntoReqFormat } from "../../helpers/product";
import { getQueryStringParameters } from '../../helpers/string';
import { debounce } from "throttle-debounce";
import ContextSaveBar from '../../components/ContextSaveBar/index';
import { getMarkets } from '../../redux/actions/marketActions';
import { connect } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import roundingDisabled from '../../assets/images/roundingDisabled.png';

let originalProductData = []
let pageMode = 'NEXT'

const MAX_ALLOWED_VARIANTS = 100;
const PRICE_CELL_STYLE = {
  borderColor: 'transparent',
  borderRight: "1px solid #D9DCE0",
};

const ProductsContainer = props => {
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([])
  const [links, setLinks] = useState({ next: null, prev: null })
  const [errorMsgForPrice, setErrorMsgForPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isShowToast, setIsShowToast] = useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false)
  const [unSavedProductIds, setUnSavedProductIds] = useState([])
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isShowSKU, setIsShowSKU] = useState(false);
  const [isShowCompareAtPrice, setIsShowCompareAtPrice] = useState(true);
  const [storeRoundings, setStoreRoundings] = useState([]);
  const [searchApiCalled, setSearchApiCalled] = useState(false);
  const [nextPageApiCalled, setNextPageApiCalled] = useState(false);
  const [openRoundingDisabledModal, setOpenRoundingDisabledModal] = useState(false);
  const [verifyRoundingApiCalled, setVerifyRoundingApiCalled] = useState(false);
  const [verifyRoundingError, setVerifyRoundingError] = useState('');
  const [roundingDisabledToast, setRoundingDisabledToast] = useState(false);
  const [pageLimit, setPageLimit] = useState([25]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [pageLimitApiCalled, setPageLimitApiCalled] = useState(false);
  const [statusApiCalled, setStatusApiCalled] = useState(false);
  const [queryValue, setQueryValue] = useState(null);
  const [status, setStatus] = useState(['all']);
  const [selectedMarketInStatus, setSelectedMarketInStatus] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalProductCountFromAPI, setTotalProductCountFromAPI] = useState(null);
  const autocompleteSearch = (searchParams) => {
    fetchProducts({ query: searchParams }, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus)
  }

  const autosizeHeaders = (gridApi) => {
    gridApi.setGroupHeaderHeight(40);
    const headerCells = document.querySelectorAll('.ag-header-group-cell div.ag-react-container');
    let minHeight = 40;
    headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
    });
    gridApi.setGroupHeaderHeight(minHeight + 10);
  }
  
  const onColumnResized = () => {
    setTimeout(() => {
      if(gridApi) {
        autosizeHeaders(gridApi);
      }
    }, 0);
  }
  
  const onSearch = (searchText) => {
    setSearchApiCalled(true);
    setStatus(['all']);
    setErrorMsgForPrice('');
    // if(status[0] === 'error') {
    //   setErrorMsgForPrice('Some products are malfunctioned!')
    // } else {
    //   setErrorMsgForPrice('')
    // }
    // fetchProducts(storeRoundings, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus, { query: searchText })
    fetchProducts(storeRoundings, pageLimit.toString(), false, selectedMarketInStatus, { query: searchText });
  }

  const autocompleteSearchDebounced = debounce(300, autocompleteSearch)

  // Copy the store markets into the local variable
  const marketOptionsForSelect = [];
  let storeMarkets = props.markets; 
  for(let market of storeMarkets) {
    marketOptionsForSelect.push({label: market.regionName, value: market.regionCode});
    if(market.setCurrencyCode) {
      const currencyCode = props.currencies.find(i => i.code === market.setCurrencyCode);
      if(currencyCode) {
        market.setCurrencySymbol = currencyCode.symbol;
      }
    }
  }
  

  const storeMarkets1 = [{
    "displayCurrencyName": "India",
    "displayCurrencyCode": "INR",
    "displayCurrencySymbol": "Rs",
    "setCurrencyName": "India",
    "setCurrencyCode": "INR",
    "setCurrencySymbol": "$",
    "currencyRoundingRule": null,
    "isPrimaryMarket": true,
    "isDeleted": false,
    "regionName": "India",
    "storeId": "amrish123.myshopify.com",
    "createdAt": "2020-11-04T12:25:02.198Z",
    "regionCode": "in",
    "regionType": "country",
    "updatedAt": "2020-11-10T15:52:39.582Z",
    "id": "5fa29d9e4bceda473b6ce21a"
  }, {
    "displayCurrencyName": "United States of America",
    "displayCurrencyCode": "USD",
    "displayCurrencySymbol": "$",
    "setCurrencyName": "Canada",
    "setCurrencyCode": "CAD",
    "setCurrencySymbol": "$",
    "currencyRoundingRule": null,
    "isPrimaryMarket": false,
    "isDeleted": false,
    "regionName": "United States of America",
    "storeId": "amrish123.myshopify.com",
    "createdAt": "2020-11-04T12:16:57.247Z",
    "regionCode": "us",
    "regionType": "country",
    "updatedAt": "2020-11-10T12:47:14.738Z",
    "id": "5fa29bb94bceda473b62c83b"
  },
  {
    "displayCurrencyName": "Canada",
    "displayCurrencyCode": "CND",
    "displayCurrencySymbol": "$",
    "setCurrencyName": "Canada",
    "setCurrencyCode": "CAD",
    "setCurrencySymbol": "$",
    "currencyRoundingRule": null,
    "isPrimaryMarket": false,
    "isDeleted": false,
    "regionName": "Canada",
    "storeId": "amrish123.myshopify.com",
    "createdAt": "2020-11-04T12:16:57.247Z",
    "regionCode": "cd",
    "regionType": "country",
    "updatedAt": "2020-11-10T12:47:14.738Z",
    "id": "5fa29bb94bceda473b62c83b"
  }
  ]

  const getParameterByName = (name, url) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const fetchProducts = (storeRoundings, pageLimit, onlyInconsistence, priceNotIn, params = null) => {
    // setIsLoading(true)
    setUnSavedProductIds([]);
    productsApi.getProductCount(params, onlyInconsistence, priceNotIn).then(count => {
      productsApi.getProducts(params, pageLimit, onlyInconsistence, priceNotIn).then((response) => {
        // response.data.multicurrency_products = response.data.multicurrency_products.slice(0, 3);
        if(response.data._links && response.data._links.prev) {
          const page = getParameterByName('page', response.data._links.prev);
          setCurrentPageNumber(parseInt(page) + 1);
        } else {
          setCurrentPageNumber(1);
        }
        setTotalProductCountFromAPI(response.data.multicurrency_products.length);
        setTotalCount(count.data.count);
        if(count.data.count <= 0) {
          setErrorMsgForPrice('');
        }
        originalProductData = [...response.data.multicurrency_products]
        const transformedProducts = transformProducts(response.data.multicurrency_products, storeMarkets, storeRoundings)
        setRowData(transformedProducts)
        const linkData = { next: response.data?._links?.next, prev: response.data?._links?.prev }
        setLinks(linkData)
      })
      .catch(err => {
        setIsShowErrorToast(true);
      })
      .finally(() => {
        setIsLoading(false);
        setSearchApiCalled(false);
        setNextPageApiCalled(false);
        setPageLimitApiCalled(false);
        setStatusApiCalled(false);
      });
    })
    .catch(countErr => {
      setIsShowErrorToast(true);
    })
  }
  useEffect(() => {
    if (props.markets.length === 0) {
      props.getMarkets()
    };
  }, [])

  useEffect(() => {
    if (props.markets.length > 0) {
      setIsLoading(true);
      roundingApi.getStoreRoundings().then((response) => {
        setStoreRoundings(response.data);
        if(response.data && response.data.rounding_rules_enabled !== undefined && !response.data.rounding_rules_enabled) {
          setOpenRoundingDisabledModal(true);
        }
        productsApi.getProductCount(null, true, selectedMarketInStatus).then(count => {
          if(count.data && count.data.count && count.data.count > 0) {
            setStatus(['error']);
            setErrorMsgForPrice("Some products are malfunctioned!");
            fetchProducts(response.data, pageLimit.toString(), true, selectedMarketInStatus);
          } else {
            fetchProducts(response.data, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus);
          }
        });
      })
      .catch(err => {
        setIsShowErrorToast(true);
      });
    }
  }, [props.markets])

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const handleCheckboxChange = useCallback((newChecked, field) => {
    if (field === "isShowSKU") {
      setIsShowSKU(newChecked)
    } else {
      setIsShowCompareAtPrice(newChecked);
    }
  }, []);

  const validateCell = params => {
    const productHavingValue = rowData.filter(
      p => p.productId == params.data.productId && p[params.colDef.field] != ""
    );
    const colId = params.column.getId();
    const regionCode = colId.split('_')[0]
    const toBeUpdatedColId = `${regionCode}_invalid`
    if (params.value == "" && (productHavingValue.length > 0 || getPrimaryMarket(storeMarkets, regionCode))) {
      //mark police cells as red
      params.node.setDataValue(toBeUpdatedColId, true);
      return { color: "black", borderColor: "red" };
    } else {
      params.node.setDataValue(toBeUpdatedColId, false);
      return PRICE_CELL_STYLE
    }
  };
  const handleDataChanged = params => {
    var colId = params.column.getId();
    if (params.newValue !== undefined && params.oldValue !== null && params.newValue != params.oldValue) {
      // params.node.setDataValue(colId, 45);
      if (params.api) {
        params.api.refreshCells({ rowNodes: params.node.parent.allLeafChildren, force: true })
        // params.api.redrawRows({ rowNodes: params.node.parent.allLeafChildren, force: true });
        // const cell = params.api.getFocusedCell()
        // if (cell) {
        //   params.api.setFocusedCell(cell.rowIndex, cell.column)
        // }
        // gridApi.refreshCells({ rowNodes: params.node.parent.allLeafChildren, columns: [params.column.colId], force: true });
        // Check 'Invalid flag' in row data
        let errorMsg = ""
        for (const row of rowData) {
          for (const storeMarket of storeMarkets) {
            if (row[`${storeMarket.regionCode}_invalid`]) {
              if (getPrimaryMarket(storeMarkets, storeMarket.regionCode)) {
                errorMsg = "Error! Primary market price can't be null.";
                break
              }
              errorMsg = "Warning! All variants of a product must have prices set in a given market.";
              break
            }
            if (errorMsg)
              break
          }
        }
        //Keep the track of updated products
        setUnSavedProductIds([...new Set([...unSavedProductIds, params.data.productId])])

        // Check for maximum 100 variants validation
        const toBeUpdatedRowData = [...rowData]
        const variants = toBeUpdatedRowData.filter((r) => r.productId == params.data.productId)
        const maxAllowedMarkets = Math.floor(MAX_ALLOWED_VARIANTS / variants.length)
        let marketsHavingValue = 0
        for (const market of storeMarkets) {
          // Check any variant for this market have a value
          let variantsHavingVal = variants.filter((v) => v[`${market.regionCode}_price`])

          if (variantsHavingVal.length > 0) {
            marketsHavingValue += 1

          }
        }

        if (marketsHavingValue >= maxAllowedMarkets) {
          for (const market of storeMarkets) {
            let variantsNotHavingVal = variants.filter((v) => !v[`${market.regionCode}_price`])
            if (variantsNotHavingVal.length === variants.length) {
              for (const v of variantsNotHavingVal) {
                v[`${market.regionCode}_invalid_for_hundred`] = true;
                v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "Exceeded Shopify limit of maximum 100 variants per product.";
              }
            }
            let variantsHavingVal = variants.filter((v) => v[`${market.regionCode}_price`])
            for (const v of variantsHavingVal) {
              v[`${market.regionCode}_invalid_for_hundred`] = false;
              v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "";
            }
          }
        } else {
          for (const market of storeMarkets) {
            for (const v of variants) {
              v[`${market.regionCode}_invalid_for_hundred`] = false;
              v[`${market.regionCode}_invalid_for_hundred_tooltip`] = "";
            }
          }
        }
        // console.log("inside", status)
        // if(status === "error") {
        //   errorMsg = "Some products are malfunctioned!";
        // }

        setRowData(toBeUpdatedRowData)
        // params.api.redrawRows({ rowNodes: params.node.parent.allLeafChildren, force: true });
        // const cell = params.api.getFocusedCell()
        // if (cell) {
        //   params.api.setFocusedCell(cell.rowIndex, cell.column)
        // }
        if (errorMsg) {
          setErrorMsgForPrice(errorMsg)
          return
        }
        setErrorMsgForPrice('');
      }
    }

  };
  const MyHeaderGroupComponent = params => {
    const values = params.displayName.split("_");
    let roundingRule = {};
    if(values[1] && storeRoundings && storeRoundings.rounding_rules) {
      roundingRule = storeRoundings.rounding_rules.find(i => i.currency === values[1]);
    }
    let isPrimaryMarket = false;
    let isROWAvailable = false;
    const primaryMarket = storeMarkets.find(i => i.isPrimaryMarket);
    if(primaryMarket.regionName === values[0]) {
      isPrimaryMarket = true;
      for(let m of storeMarkets) {
        if(m.regionCode === "intl") {
          isROWAvailable = true;
          break;
        }
      }
    }
    
    const btnClickedHandler = () => {
      params.clicked(params);
    };
    return (
      <div className="productHeader">
        <div><span>{values[0]}{isPrimaryMarket && !isROWAvailable ? " + Rest of the world" : ""}</span>{' '}
          {values[1] && <span>({values[1]})</span>}
          {/* <span className="priceHeader">
            <Tooltip content="This is tooltip for pricing header">
              <Icon source={CircleInformationMajor} color="base" />
            </Tooltip>
          </span> */}
        </div>
        {values[1] && roundingRule && <div className="roundedTo">Rounded to {roundingRule.rule === 1 ? roundingRule.rule.toFixed(2) : roundingRule.rule}</div>}
        {/* <span>
          {values[0]} {values[1] && <span>({(values[1])}{''}
          {roundingRule ? ", rounding to " + roundingRule.rule : ""}</span>})
          <span className="priceHeader">
            <Tooltip content="This is tooltip for pricing header">
              <Icon source={CircleInformationMajor} color="base" />
            </Tooltip>
          </span>
        </span> */}
        {/* <button onClick={btnClickedHandler}>Edit</button> */}
      </div>
    );
  };

  const productTitleComponent = params => {
    return (
      <div>
        {params.data.productImage && <span className="variantImg"><img src={params.data.productImage} /></span>}
        <span>{params.value}</span>
      </div>
    );
  }

  const handleHeadClick = field => {
    alert(`${field.displayName} was clicked`);
  };


  const updateSearchText = useCallback(
    (value) => {
      setSearchText(value);
      // autocompleteSearchDebounced(value)
    },
    [isLoading],
  );
  /**
   * Call the Api to get the next set of products
   */
  const getPreviousProducts = () => {
    if (unSavedProductIds.length > 0) {
      setIsConfirmModal(true)
      pageMode = 'PREV'
    } else {
      const pageObj = getQueryStringParameters(links.prev);
      setNextPageApiCalled(true);
      if(status[0] === 'error') {
        setErrorMsgForPrice('Some products are malfunctioned!')
      } else {
        setErrorMsgForPrice('')
      }
      fetchProducts(storeRoundings, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus, pageObj)
    }
  }
  /**
  * Call the Api to get the previous set of products
  */
  const getNextProducts = () => {
    if (unSavedProductIds.length > 0) {
      setIsConfirmModal(true)
      pageMode = 'NEXT'
    } else {
      const pageObj = getQueryStringParameters(links.next);
      setNextPageApiCalled(true);
      if(status[0] === 'error') {
        setErrorMsgForPrice('Some products are malfunctioned!')
      } else {
        setErrorMsgForPrice('')
      }
      fetchProducts(storeRoundings, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus, pageObj)
    }
  }
  /**
   * Do the transformation of the data as api expects
   */
  const saveProducts = () => {
    const putData = { multicurrency_products: convertProductsIntoReqFormat(unSavedProductIds, rowData, storeMarkets) }
    setIsLoading(true)
    productsApi.updateProducts(putData).then((response) => {
      setIsShowToast(true)
      setUnSavedProductIds([]);
      for(let data of putData.multicurrency_products) {
        const originalData = originalProductData.find(i => i.id === data.id);
        if(originalData) {
          originalData.variants = data.variants;
        }
      }
      const transformedProducts = transformProducts(originalProductData, storeMarkets, storeRoundings)
      setRowData(transformedProducts);
    }).catch((error) => {
      setIsShowErrorToast(true)
    }).finally(() => {
      setIsLoading(false)

    })
  }
  /**
   * Reset all the changes
   */
  const discardChanges = () => {
    setIsLoading(true)
    if(status[0] === 'error') {
      setErrorMsgForPrice('Some products are malfunctioned!')
    } else {
      setErrorMsgForPrice('')
    }

    const transformedProducts = transformProducts(originalProductData, storeMarkets, storeRoundings)
    setUnSavedProductIds([])
    // setRowData([])
    setTimeout(() => {
      setRowData([...transformedProducts])
      setIsLoading(false)
    }, 200);
  }
  const leavePage = () => {
    setIsConfirmModal(false)
    let pageObj = getQueryStringParameters(links.next);
    if (pageMode === 'NEXT') {
      pageObj = getQueryStringParameters(links.next);
    } else {
      pageObj = getQueryStringParameters(links.prev);
    }
    setNextPageApiCalled(true);
    if(status[0] === 'error') {
      setErrorMsgForPrice('Some products are malfunctioned!')
    } else {
      setErrorMsgForPrice('')
    }
    fetchProducts(storeRoundings, pageLimit.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus, pageObj)
  }
  const closeModal = () => {
    setIsConfirmModal(false)
  }

  const getRowStyle = (params) => {
    if(params.data && params.data.inconsistentProduct) {
      return {'background-color': ' #fdf3f0', color: "red"}
    }
  }

  const keyPressEvent = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if(enterKeyPressed) {
      onSearch(searchText);
    }
  }

  const toggleRoundingToast = useCallback(() => setRoundingDisabledToast((toast) => !toast), []);

  const roundingDisabledToastMarkup = roundingDisabledToast ? (
    <Toast content="Rounding is disabled." onDismiss={toggleRoundingToast} />
  ) : null;

  const roundingVerify = () => {
    setVerifyRoundingApiCalled(true);
    roundingApi.verifyRounding().then(verfiyData => {
      if(verfiyData.data.rounding_rule_status) {
        window.location.reload();
      } else {
        setRoundingDisabledToast(true);
        setVerifyRoundingApiCalled(false);
      }
    })
    .catch(verfiyErr => {
      setVerifyRoundingApiCalled(false);
      setVerifyRoundingError(true);
    })
  }

  const handlePageLimitChange = (value) => {
    setPageLimitApiCalled(true);
    setPageLimit(value);
    fetchProducts(storeRoundings, value.toString(), status[0] === 'error' ? true : false, selectedMarketInStatus)
  }

  const handleStatusChange = (value) => {
    setStatusApiCalled(true);
    if(value[0] !== "empty") {
      setSelectedMarketInStatus(null);
    } else {
      setSelectedMarketInStatus(marketOptionsForSelect[0].value);
    }
    setStatus(value);
    if(value[0] === 'error') {
      setErrorMsgForPrice('Some products are malfunctioned!')
    } else {
      setErrorMsgForPrice('')
    }
    fetchProducts(storeRoundings, pageLimit, value[0] === 'error' ? true : false, value[0] === 'empty' ? marketOptionsForSelect[0].value : '')
  }

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
  },[]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {`${pageLimit.toString()} Products/Page`}
    </Button>
  );

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case 'productsPerPage':
        return `Products per page is ${pageLimit}`;
      case 'status':
        return `Show is ${status[0] === 'empty' ? 'Products whose price is not set in the market' : (status[0] === 'all' ? 'All products' : 'Products having issues')}`;
      default:
        return value;
    }
  }

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const handleProductsPerPageRemove = useCallback(() => setPageLimit(null),[]);

  const handleStatusRemove = useCallback(() => setStatus(null),[]);

  const handleSelectChange = (val) => { 
    setStatusApiCalled(true);
    setSelectedMarketInStatus(val);
    if(status[0] === 'error') {
      setErrorMsgForPrice('Some products are malfunctioned!')
    } else {
      setErrorMsgForPrice('')
    }
    fetchProducts(storeRoundings, pageLimit, status[0] === 'error' ? true : false, val)
  };

  const onClearAll = () => {
    setStatusApiCalled(true);
    setPageLimit([25]);
    setStatus(['all']);
    setErrorMsgForPrice('');
    fetchProducts(storeRoundings, [25], false, null)
  }

  const getSaveButtonState = errorMsgForPrice ? true : false
  if (rowData.length <= 0 && isLoading && !searchText) {
    return (<div style={{ height: '100px' }}>
      <Frame>
        <Loading />
      </Frame>
    </div>)
  }

  // if (rowData.length <= 0 && !searchText)
  //  return null

  const filters = [
    {
      key: 'productsPerPage',
      label: 'Product/Page',
      filter: (
        <ChoiceList
            title={`Product/Page`}
            titleHidden
            choices={[
              {label: '25', value: 25},
              {label: '50', value: 50},
              {label: '100', value: 100},
            ]}
            selected={pageLimit || [25]}
            onChange={(e) => { handlePageLimitChange(e) }}
          />
      ),
    },
    {
      key: 'status',
      label: 'Show',
      filter: (
        <>
          <ChoiceList
            title="Show"
            titleHidden
            choices={[
              {label: 'All products', value: 'all'},
              {label: 'Products having issues', value: 'error'},
              {label: 'Products whose price is not set in the market', value: 'empty'},
            ]}
            selected={status || []}
            onChange={(e) => { handleStatusChange(e) }}
          />
          {status[0] === 'empty' && <div className="mt-10">
            <Select
              // label="Date range"
              options={marketOptionsForSelect}
              onChange={(e) => { handleSelectChange(e) }}
              value={selectedMarketInStatus}
            />
          </div>}
        </>
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(pageLimit)) {
    const key = 'productsPerPage';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, pageLimit),
      onRemove: handleProductsPerPageRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = 'status';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }

  return (
    <>
      {/* {unSavedProductIds.length > 0 && <ContextSaveBar
        disabled={getSaveButtonState}
        onSave={saveProducts}
        onDiscard={discardChanges}
        isLoading={isLoading} />
      } */}
      {unSavedProductIds.length > 0 && <ContextualSaveBar
            contextControl={
              <Stack alignment='center' spacing='tight' distribution='leading'>
                <Stack.Item>
                  <Image
                    alt=''
                    source={logo}
                    className='app__contexualSaveBar__logo'
                  />
                </Stack.Item>
                <Stack.Item>
                  <span className='app__contexualSaveBar__titleText'>
                  Multi Country Pricing
            </span>
                </Stack.Item>
              </Stack>
            }
            message="Unsaved changes"
            saveAction={{
              onAction: () => saveProducts(),
              loading: isLoading,
              disabled: getSaveButtonState,
            }}
            discardAction={{
              onAction: () => discardChanges(),
            }}
          />}
      {!isLoading && <div>
        <ManageProducts
          searchText={searchText}
          updateSearchText={updateSearchText}
          onSearch={onSearch}
          searchApiCalled={searchApiCalled}
          keyPressEvent={keyPressEvent} />

        <div className={Styles["editing-fields"]}>
          {/* <div className={Styles["label"]}>Fields:</div> */}
          <div className={Styles["fields"]}>
            <div className={Styles["choise-list"]}>
              <Checkbox
                checked={isShowCompareAtPrice}
                label={<span>Compare Price</span>}
                onChange={newChecked => {
                  handleCheckboxChange(
                    newChecked,
                    'isShowCompareAtPrice',
                  );
                }}
              />
            </div>
            <div className={Styles["choise-list"]}>
              <Checkbox
                checked={isShowSKU}
                label={<span>SKU</span>}
                onChange={newChecked => {
                  handleCheckboxChange(
                    newChecked,
                    'isShowSKU',
                  );
                }}
              />
            </div>
          </div>

          <div className="more-filters">
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onClearAll={() => { onClearAll() }}
            />
          </div>

        </div>

        {/* <div className={`${Styles["fileter-main"]} product-filter`}>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={(e) => { togglePopoverActive(e) }}
        >
          <ChoiceList
            title={`Product/Page`}
            titleHidden
            choices={[
              {label: '25', value: 25},
              {label: '50', value: 50},
              {label: '100', value: 100},
            ]}
            selected={pageLimit || [25]}
            onChange={(e) => { handlePageLimitChange(e) }}
          />
        </Popover>
          
        </div> */}

        <div className={"justify-content-center " + Styles["pagination-section"]}>
          <span className={Styles["pageNum"]}>
            {totalProductCountFromAPI <= 0 ? 0 : (currentPageNumber*pageLimit) - pageLimit + 1}-{totalProductCountFromAPI <= 0 ? 0 : (currentPageNumber*pageLimit) - pageLimit + totalProductCountFromAPI} of {totalCount}
          </span>
          <Pagination
            hasPrevious={links.prev}
            onPrevious={getPreviousProducts}
            hasNext={links.next}
            onNext={getNextProducts}
            previousKeys={[74]}
            previousTooltip="Previous"
            nextKeys={[75]}
            nextTooltip="Next"
          />
        </div>
      </div>}
        
      <div className={Styles["wrapper"]}>
        <div className={`ag-theme-alpine ${Styles['grid-container']}`} style={{height: '100%'}}>
          {(isLoading || searchApiCalled || nextPageApiCalled || pageLimitApiCalled || statusApiCalled) && <div className={Styles['spinner-wrapper']}>
            <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
          </div>
          }
          {errorMsgForPrice && (<Banner
            status="critical"
          >
            <DisplayText size="small">{errorMsgForPrice}
            {" "}{errorMsgForPrice.includes("Error! Primary market price can't be null") && <span>Mail us: <span className="emailLink">support@mlveda.com</span></span>}
            {" "}{errorMsgForPrice.includes("Some products are malfunctioned!") && <span><span className="resolveNowBtn"><Button primary onClick={() => { window.open("https://youtu.be/B0mp5sgXbyY", "_blank") }}>Resolve now</Button></span><Link onClick={() => { handleStatusChange(["all"]) }}> I'll do it later</Link></span>}
            {/* <Link>Solve it</Link> */}
            </DisplayText>
          </Banner>)}
          {(!isLoading && !searchApiCalled && !nextPageApiCalled && !pageLimitApiCalled && !statusApiCalled) && <AgGridReact
            rowData={rowData}
            rowSelection="multiple"
            groupSelectsChildren={true}
            enableRangeSelection={true}
            enableFillHandle={true}
            fillHandleDirection="y"
            // enableCellTextSelection={true}
            enterMovesDownAfterEdit={true}
            suppressRowClickSelection={true}
            // frameworkComponents={{'numericCellEditor': NumericCellEditor}}
            suppressContextMenu={true}
            pagination={false}
            paginationPageSize={10}
            suppressPaginationPanel={true}
            groupMultiAutoColumn={true}
            groupDefaultExpanded={-1}
            rowGroupPanelShow={"never"}
            groupUseEntireRow={true}
            onGridReady={onGridReady}
            onColumnResized={onColumnResized}
            onCellValueChanged={params => handleDataChanged(params)}
            frameworkComponents={{ genderCellRenderer: MyHeaderGroupComponent }}
            getRowStyle={params => getRowStyle(params)}
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            suppressMovableColumns={true}
            overlayNoRowsTemplate='<span style="font-size: 1.6rem;">No products found!</span>'
            defaultColDef={{
              flex: 1,
              minWidth: 110,
              editable: true,
              resizable: true
            }}
          >
            <AgGridColumn
              field="productId"
              headerName="Title"
              rowGroup={true}
              hide={true}
              valueFormatter={(p) => rowGroupHeaderName(rowData, p)}
            />

            <AgGridColumn
              field="variantTitle"
              tooltipField="variantTitle"
              editable={false}
              headerName="Product"
              pinned="left"
              cellStyle={{
                "background-color": "#f7f7f7",
                borderRight: "1px solid #D9DCE0"
              }}
              cellRendererFramework={productTitleComponent}
            />
            {
              storeMarkets.map((storeMarket) => (
                
                <AgGridColumn
                  key={storeMarket.id}
                  field={storeMarket.regionCode}
                  headerName={`${storeMarket.regionName}_${storeMarket.setCurrencyCode}`}
                  headerGroupComponent="genderCellRenderer"
                  headerGroupComponentParams={{
                    clicked: handleHeadClick,
                    header: "UnitedStates"
                  }}
                >
                  <AgGridColumn
                    field={`${storeMarket.regionCode}_invalid`}
                    headerName="Price"
                    hide={true}
                    // onCellValueChanged={params => handleDataChanged(params)}
                  />
                  <AgGridColumn
                    field={`${storeMarket.regionCode}_price`}
                    headerName="Price"
                    cellStyle={validateCell}
                    valueFormatter={param =>
                      numberValueFormatter(param, "europeSymbol")
                    }
                    // cellClass={(params) => handleCellClass(params)}
                    editable={(params) => checkEditableFlag(params)}
                    valueParser={param => numberParser(param, storeRoundings)}
                    minWidth="100"
                    cellClassRules={{
                      'disabled': params => {
                        if(params && params.colDef) {
                          const regionCode = params.colDef.field.split('_')[0];
                          const colId = `${regionCode}_invalid_for_hundred`;
                          if(regionCode === params.data.primayMarket) {
                            return false;
                          }
                          if(!params.data.isEditable || params.data[colId]) {
                            return true;
                          } else {
                            return false
                          }
                        } else {
                          return false;
                        }
                      }
                    }}
                    tooltipField={`${storeMarket.regionCode}_invalid_for_hundred_tooltip`}
                    // onCellValueChanged={params => handleDataChanged(params)}
                  />
                  <AgGridColumn
                    field={`${storeMarket.regionCode}_compare_at_price`}
                    headerName="Compare Price"
                    cellStyle={PRICE_CELL_STYLE}
                    valueFormatter={param =>
                      numberValueFormatter(param, "europeSymbol")
                    }
                    minWidth="115"
                    // cellClass={(params) => handleCellClass(params)}
                    editable={(params) => checkEditableFlag(params)}
                    valueParser={param => numberParser(param, storeRoundings)}
                    hide={!isShowCompareAtPrice}
                    cellClassRules={{
                      'disabled': params => {
                        if(params && params.colDef) {
                          const regionCode = params.colDef.field.split('_')[0];
                          const colId = `${regionCode}_invalid_for_hundred`;
                          if(regionCode === params.data.primayMarket) {
                            return false;
                          }
                          if(!params.data.isEditable || params.data[colId]) {
                            return true;
                          } else {
                            return false
                          }
                        } else {
                          return false;
                        }
                      }
                    }}
                    tooltipField={`${storeMarket.regionCode}_invalid_for_hundred_tooltip`}
                  />
                  <AgGridColumn
                    field={`${storeMarket.regionCode}_sku`}
                    headerName="SKU"
                    cellStyle={PRICE_CELL_STYLE}
                    // cellClass={(params) => handleCellClass(params)}
                    editable={(params) => checkEditableFlag(params)}
                    minWidth="150"
                    hide={!isShowSKU}
                    cellClassRules={{
                      'disabled': params => {
                        if(params && params.colDef) {
                          const regionCode = params.colDef.field.split('_')[0];
                          const colId = `${regionCode}_invalid_for_hundred`;
                          if(regionCode === params.data.primayMarket) {
                            return false;
                          }
                          if(!params.data.isEditable || params.data[colId]) {
                            return true;
                          } else {
                            return false
                          }
                        } else {
                          return false;
                        }
                      }
                    }}
                    tooltipField={`${storeMarket.regionCode}_invalid_for_hundred_tooltip`}
                  />
                </AgGridColumn>
              ))
            }
          </AgGridReact>
          }

        </div>
      </div>
      {isShowToast && <Frame>
        <Toast
          duration={1500}
          content="Products have been updated successfully."
          onDismiss={() => { setIsShowToast(false) }} />
      </Frame>}
      {(isShowErrorToast || verifyRoundingError) && <Frame>
        <Toast
          duration={1500}
          error
          content="Something went wrong."
          onDismiss={() => { setIsShowErrorToast(false) }} />
      </Frame>}
      {roundingDisabledToastMarkup}
      <Modal
        open={isConfirmModal}
        onClose={closeModal}
        primaryAction={{
          content: 'Leave',
          onAction: leavePage,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Please save your changes and go to next page else your current changes will be discarded.
              <br />
              Are you sure you want to leave page?
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal
        title="Enable Price rounding in Shopify payments"
        open={openRoundingDisabledModal}
        primaryAction={{
          content: "Verify",
          loading: verifyRoundingApiCalled,
          disabled: verifyRoundingApiCalled,
          onAction: () => roundingVerify(),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>
            Please enable <span className="bold-font">Price rounding</span> in your Shopify payments. Our app will not work unless you enable rounding.
            </p>
            <p className="mt-15">Open this <Link onClick={() => { window.open(`https://${props.auth.myshopify_domain}/admin/settings/payments/shopify-payments`) }}>page</Link> and click Enable button in the <span className="bold-font">Price rounding</span> section as shown below. Your dashboard will be enabled within an hour after enabling it</p>
            <img className="roundingImg" src={roundingDisabled} alt="Rounding" />
            <p className="mt-15">Please write to us at <span className="emailLink">support@mlveda.com</span> if you have any queries</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    isMarketLoading: state.markets.isLoading,
    markets: state.markets.markets,
    currencies: state.currencies.currencies,
    auth: state.auth.storeDetails
  }
}
const mapDispatchToProps = {
  getMarkets
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsContainer));
